import classes from "./header.module.css";
import "./header.module.css";
import { Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faChartPie,
  faExchangeAlt,
  faHome,
  faCircle,
  faPhone,
  faBell,
  faQuestion,
  faSignOutAlt,
  faUserFriends,
  faVideo,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import logoProject, {
  isDistObserver,
  isLabrarianOrDirector,
  isRegObserver,
  replaceString,
} from "../../lib/helper";
import { useHistory } from "react-router";
import { storage } from "../../service";
import { thunks } from "../../feature/proflle/model";
import { useActions, useAppSelector } from "../../hooks";
import "./mobile.css";
import { api } from "../../service/old-api-2";
import { useEffect, useState } from "react";
import { ButtonC } from "../buttons/button";
import { read } from "../../service/storage";

const actions = {
  signOut: () => thunks.signOut(true),
};
export const Header = () => {
  const { signOut } = useActions(actions);
  const user = useAppSelector((state) => state.profile.user);
  const school = useAppSelector((state) => state.profile.school);
  const { t } = useTranslation();
  const history = useHistory();
  const [balance, setBalance] = useState();
  const [unredNotificationsCount, setUnreadNotificationsCount] = useState({});
  const access = read("access");
  const selectSetting = (eventKey) => {
    switch (eventKey) {
      case "sign-out":
        signOut();
        break;
      case "profile":
        history.push("/profile");
        break;
      case "payments":
        history.push("/payments");
        break;
    }
  };
  const getBalance = () => {
    api
      .get("school/")
      .then((response) => {
        setBalance(response);
      })
      .catch(() => {
      });
  };
  const getNotifications = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/notifications/count_unread/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setUnreadNotificationsCount(data));
  };
  useEffect(() => {
    getBalance();
    getNotifications();
  }, []);
  return (
    <div className={classes.header}>
      {/* <div className={classes.topbar}>
        <div className="container">
          <div className="row">
            <div className="col mt-2 phone-numbers">
              <i className="fa-brands fa-whatsapp"></i> +996 (706) 60 80 10
            </div>
            <div className="col mt-2 phone-numbers">
              <i className="fa-brands fa-whatsapp"></i> +996 (556) 60 80 10
            </div>
            <div className="col mt-2 phone-numbers">
              <i className="fa-solid fa-phone"></i> +996 (776) 60 80 10
            </div>
          </div>
        </div>
      </div> */}
      <div className={classes.topbar}>
        <div className="container h-100">
          <div className="d-flex h-100 align-items-center justify-content-center">
            {isLabrarianOrDirector && (
              <>
                <div className="mx-5" title={school?.name}>
                  <span>{t("header.school-name")}</span>{" "}
                  {replaceString(school?.name || "")}
                </div>
                <div className={classes.schoolId + "col"}>
                  <span>{t("header.school-number")}</span>{" "}
                  {storage.read("school_id")}
                </div>
                <b>
                  <p className="mx-5">
                    <span>{t("header.balance")}</span> {balance?.balance}
                  </p>
                </b>
              </>
            )}
            {isDistObserver && (
              <div className="mx-5">
                <span>{t("header.district")}</span> {storage.read("district")}
              </div>
            )}
            {isRegObserver && (
              <div className="mx-5">
                <span>{t("header.region")}</span> {storage.read("region")}
              </div>
            )}
            <div className="mx-5" title={user?.fullname}>
              <span>{t("header.employee")}</span>{" "}
              {replaceString(user?.fullname)}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.header}>
        <Navbar bg="light" variant="light" collapseOnSelect expand="lg">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Brand>
            <Link to="/" style={{ color: "rgb(0 136 225)" }}>
              <strong style={{ textTransform: "uppercase" }}>
                {" "}
                <img className={classes.logo} src={logoProject} alt={"logo"} />
              </strong>
            </Link>
          </Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className={`${classes.mainHeadMenuUl} ms-auto d-flex align-items-center text-dark justify-content-center w-100`}
              onSelect={selectSetting}
            >
              <NavLink
                exact
                to="/news"
                className="ms-md-auto"
                activeClassName={classes.active}
              >
                <FontAwesomeIcon icon={faHome} className="mx-3" />
                {t("menu.news")}
              </NavLink>
              <NavLink exact to="/videos" activeClassName={classes.active}>
                <FontAwesomeIcon icon={faVideo} className="mx-3" />{" "}
                {t("menu.videos")}
              </NavLink>
              <NavLink exact to="/questions" activeClassName={classes.active}>
                <FontAwesomeIcon icon={faQuestion} className="mx-3" />{" "}
                {t("menu.questions")}
              </NavLink>
              {isLabrarianOrDirector && (
                <>
                  {/* <NavLink exact to="/store" activeClassName={classes.active}>
                    <FontAwesomeIcon icon={faStore} className="mx-3" />
                    Магазин
                  </NavLink> */}
                  <NavLink
                    activeClassName={classes.active}
                    exact
                    to="/payments"
                  >
                    <FontAwesomeIcon icon={faMoneyBill} className="mx-3" />{" "}
                    {t("payment.title")}
                  </NavLink>
                </>
              )}
              <NavLink exact to="/contacts" activeClassName={classes.active}>
                <FontAwesomeIcon icon={faPhone} className="mx-3" />{" "}
                {t("menu.contacts")}
              </NavLink>
              <NavLink
                exact
                to="/notifications"
                activeClassName={classes.active}
                style={{ position: "relative" }}
              >
                <FontAwesomeIcon icon={faBell} className="mx-3" />
                {unredNotificationsCount > 0 && (
                  <FontAwesomeIcon
                    style={{
                      color: "red",
                      fontSize: "13px",
                      position: "absolute",
                      left: "23px",
                      top: "9px",
                    }}
                    icon={faCircle}
                    className="mx-3"
                  />
                )}{" "}
                {t("menu.notifications")}
                {unredNotificationsCount > 0 && (
                  <span> ({unredNotificationsCount} новых) </span>
                )}
              </NavLink>
              <a
                href="https://okuukitebi.edu.kg"
                target="_blank"
                rel="noreferrer"
                style={{ position: "relative" }}
              >
                {t("menu.jk-site")}
              </a>
              {/* <NavLink exact to="/" className="text-dark only-mobile">
                <FontAwesomeIcon icon={faHome} className="mx-3 text-dark" />
                {t("menu.main")}
              </NavLink> */}
              <NavLink exact to="/classes" className="text-dark only-mobile">
                <FontAwesomeIcon icon={faUserFriends} className="mx-3" />
                {t("menu.classes")}
              </NavLink>
              <NavLink
                exact
                to="/school-books"
                className="text-dark only-mobile"
              >
                <FontAwesomeIcon icon={faBook} className="mx-3" />{" "}
                {t("menu.school_books")}
              </NavLink>
              <NavLink
                exact
                to="/action-books"
                className="text-dark only-mobile"
              >
                <FontAwesomeIcon icon={faExchangeAlt} className="mx-3" />{" "}
                {t("menu.action_books")}
              </NavLink>
              {/* <NavLink exact to="/videos" className="text-dark only-mobile">
                <FontAwesomeIcon icon={faVideo} className="mx-3" />{" "}
                {t("menu.videos")}
              </NavLink> */}
              <NavLink exact to="/report" className="text-dark only-mobile">
                <FontAwesomeIcon icon={faChartPie} className="mx-3" />{" "}
                {t("menu.report")}
              </NavLink>
              {/* <NavLink exact to="/contacts" className="text-dark only-mobile">
                <FontAwesomeIcon icon={faPhone} className="mx-3" />{" "}
                {t("menu.contacts")}
              </NavLink> */}
              {/* <NavLink exact to="/questions" className="text-dark only-mobile">
                <FontAwesomeIcon icon={faQuestion} className="mx-3" /> Вопросы и
                Ответы
              </NavLink> */}
              <a href="kitep.org.kg" className="text-dark only-mobile">
                <FontAwesomeIcon icon={faQuestion} className="mx-3" />{" "}
                {t("menu.jk-site")}
              </a>
              <ButtonC
                className="bg-light text-dark border-light ms-auto"
                onClick={() => signOut()}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  style={{ marginRight: "10px" }}
                />
                {t("header.setting.sign-out")}
              </ButtonC>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};
