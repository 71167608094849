import React, { useEffect, useState } from "react";

import {
  AddSchoolBook,
  SchoolBookItem,
  SearchSchoolBooks,
} from "../feature/school-books";
import { useTranslation } from "react-i18next";
import { Layout } from "../app/layout";
import { ButtonC, DeleteNotifyWithRedux, InputField, TableComponent } from "../components";
import { useAppSelector, usePageTitle } from "../hooks";
import "./styles.css";
import { isLibrarian } from "../lib/helper";
import { api } from "../service/old-api-2";
import {Link} from "react-router-dom";
import { isDistObserver, isLabrarian, isJanyKitep } from "../lib/helper";
import {applicationType} from '../lib/helper'
import styles from "../components/oneBook/oneBook.module.css";
import bookImg from "../img/placeholder.png";
import classes from '../feature/school-books/books.module.css';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useActions} from '../hooks';
import {read} from "../service/storage";
import {useForm} from "react-hook-form";
import {
  notifySuccess,
  notifyError,
  notifyInfo,
} from "../components/notify";
import { useHistory } from "react-router-dom";
import { DeleteNotifyWithReduxWithYear } from "../components/delete-notify/del-not-with-redux-with-year";

export const BooksFondApplicationNew = () => {
  const {control} = useForm()
  const { t } = useTranslation();
  usePageTitle(t("school-books.title"));
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [newBooks, setNewBooks] = useState([]);
  const [applications, setApplications] = useState([]);
  const schoolBooks = useAppSelector((state) => state.schoolBooks.searchResult);
  const [selectedFile, setSelectedFile] = useState(null)
  const [comment, setComment] = useState(null)
  const [bookForDelete, setBookForDelete] = useState(null)
  const history = useHistory();
  // const BooksInApplication = () => {
  //   api.get(`school-books-applications/`).then((response) => {
  //     setApplications(response);
  //     // console.log(response);
  //     // if (response.status === 'success') {
  //     //     console.log();
  //     // }
  //   });
  // };
  // console.log(orders);
  useEffect(() => {
    // dispatch(actions.fetchOrders())
    // GeneralOrderlist();
  }, []);

  const handleShow = () => {
    setShow(!show);
  };
  // const onFocus = (id) => {
  //   const btn = document.querySelector(`#button${id}`)
  //   btn.style.borderColor = '#0d6efd'
  //   btn.style.color = 'black'
  // }

  // const onBlur = (id) => {
  //     const btn = document.querySelector(`#button${id}`)
  //     btn.style.borderColor = '#90909a'
  //     btn.style.color = 'black'
  // }
  const handleTotalChange = (bookId, bookYear, newValue, grade) => {
    if (newValue === '') {
      newValue = 0
    }
    // Найдите индекс учебника в newBooks по его bookId и году
    const bookIndex = newBooks.findIndex((book) => book.id === bookId && book.year === bookYear);
    if (bookIndex !== -1) {
      // Создайте копию списка newBooks
      const updatedBooks = [...newBooks];
      // Обновите значение total для выбранного учебника
      if (grade) {
        if (!updatedBooks[bookIndex].gradeCount) {
          updatedBooks[bookIndex].gradeCount = {};
          updatedBooks[bookIndex].gradeCount[grade] = newValue;
        } else {
          if (updatedBooks[bookIndex].gradeCount[grade]) {
            updatedBooks[bookIndex].total -= updatedBooks[bookIndex].gradeCount[grade]
          }
          updatedBooks[bookIndex].gradeCount[grade] = newValue;
        }
        updatedBooks[bookIndex].total += parseInt(newValue);
      } else {
        updatedBooks[bookIndex].total = parseInt(newValue);
      }
      // Обновление состояния явно (можно добавить, хотя и не обязательно)
      setNewBooks(updatedBooks);
      console.log(updatedBooks);
    }
  };

  const deleteSchoolBook = (bookId, bookYear) => {
    const bookIndex = newBooks.findIndex((book) => book.id === bookId && book.year === bookYear);
    if (bookIndex !== -1) {
      // Создайте копию списка newBooks
      const updatedBooks = [...newBooks];
      // Удалим элемент по индексу
      updatedBooks.splice(bookIndex, 1);
      // Обновление состояния явно (можно добавить, хотя и не обязательно)
      // console.log(newBooks);
      setNewBooks(updatedBooks);
    }
  }

  // useEffect(() => {
  //   console.log(newBooks); // Выведет актуальное состояние после обновления
  // }, [newBooks]); // Эффект будет запускаться при изменении newBooks
  // const selectedFileHandler = (event) => {
  //   setSelectedFile(event.target.files[0])
  // }

  const changeComment = (comment) => {
    setComment(comment)
  }

  const selectedFileBooksUploadHandler = () => {
    const access = read('access')
    if (newBooks.length > 0) {
      const fd = new FormData()
      // fd.append("application_doc", selectedFile, selectedFile.name)
      fd.append("books", JSON.stringify(newBooks))
      fd.append("comment", comment)
      const uri = `${process.env.REACT_APP_BASE_URL}/api/fund-add-application/`
      fetch(uri, {
          method: 'POST',
          body: fd,
          headers: {
              "Authorization": `Bearer ${access}`,
          }
      }).then((response) => {
        if (response.ok) {
          notifySuccess(
            t('school-books.addBook.added_text')
          );
          history.push("/school-books");
        } else if (response.status === 400 || response.status === 500) {
            response.json().then((errorData) => {
              const errorMessage = errorData.message
              notifyError(errorMessage)
            })
        }
      })
    } else if (newBooks.length > 0) {
        notifyError(t('school-books.addBook.no_file_text'))
    } else if (newBooks.length === 0) {
      notifyError(t('school-books.addBook.no_book_text'))
    } else if (newBooks.length === 0) {
      notifyError(t('school-books.addBook.no_file_book_text'))
    }
  }

  const setToDeleteBook = (book) => {
    setBookForDelete(book)
    setDeleteShow(true)
  }

  return (
    <Layout>
      <DeleteNotifyWithReduxWithYear
        func={deleteSchoolBook}
        id={bookForDelete?.id}
        year={bookForDelete?.year}
        show={deleteShow}
        setShow={setDeleteShow}
        message={bookForDelete?.name}
      />
      <AddSchoolBook show={show} handleShow={handleShow} book={schoolBooks} newBooks={newBooks} setNewBooks={setNewBooks}/>
      <h2 className="mb-4 book-fond-title">
        {t('school-books.addBook.title')}
      </h2>
      {/* {isLibrarian && (
        <ButtonC
          variant="add"
          className="ms-auto  add-books-btn mb-3 mx-3"
          onClick={handleShow}
        >
          {t("school-books.add.btn")}{" "}
        </ButtonC>
      )} */}
      {/* <SearchSchoolBooks /> */}
      {/* <input
          type="file"
          accept="application/pdf"
          placeholder="Choose a file"
          onChange={selectedFileHandler}
          className="mt-4"
      /><br /> */}
      {/* <span><b>{t('school-books.addBook.input')}</b></span> */}
      {/* <button className="btn btn-success text-white" onClick={selectedFileUploadHandler}>Загрузить файл и подтвердить</button> */}
      {/* <br /> <br /> */}
      {isLibrarian && (
        <ButtonC variant="add" className="ms-auto add-books-btn mb-3 mx-3" onClick={handleShow}>
          {t("school-books.add.btn")}
        </ButtonC>
      )}
      <p><b>{t('school-books.addBook.choice_book')}</b></p>
      <br />
      <TableComponent
        style={{ fontSize: "0.85rem" }}
        className="book-fond-table"
      >
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{t("school-books.id")}</th>
            <th scope="col">{t("school-books.book_author")}</th>
            <th scope="col">{t('school-books.img')}</th>
            <th scope="col">{t("school-books.class")}</th>
            <th scope="col">{t("school-books.year_publish")}</th>
            <th scope="col">{t("school-books.language")}</th>
            <th scope="col" className="noWrap">
              {t("school-books.rent")}
            </th>
            <th scope="col">{t("school-books.book_count")}</th>
            {isLibrarian && <th scope="col">{t("school-books.delete")}</th>}
          </tr>
        </thead>
        <tbody>
          {newBooks?.map((applicationBook, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{applicationBook["id"]}</td>
              <td scope="col"
                data-label="Учебники / Автор">{applicationBook.name} {applicationBook.number_of_parts > 1 ? <>({applicationBook.part}-{t('school-books.part')})</> : ''}<br/>{applicationBook.author}
              </td>
              <td scope="col" data-label="Изображение">
                  <div className={`${styles.imgWrap} my-4 ms-auto me-auto`}>
                      { applicationBook.image && <img src={applicationBook.image} alt="1" />}
                      { !applicationBook.image && <img src={bookImg} alt="1" />}
                  </div>
              </td>
              <td scope="col" data-label="Класс">{(applicationBook.grades).join(',')} кл</td>
              <td scope="col" data-label="Год издания">{applicationBook.year}</td>
              <td scope="col" data-label="Язык обучения">{applicationBook.language}</td>
              <td scope="col" data-label="В аренде">
                {/* <input type="checkbox" name={applicationBook.book.id} className="me-1" defaultChecked={applicationBook.book.subject_to_renting}
                        disabled={!applicationBook.book.subject_to_renting}/> */}
                {/* <input type="checkbox" name={applicationBook.book.id} className="me-1" defaultChecked={applicationBook.book.subject_to_renting}
                  disabled={true}/> */}
                <label
                      htmlFor={applicationBook.id}>{applicationBook.subject_to_renting ? t('school-books.yes') : t('school-books.no')}</label>
              </td>
              <td scope="col" data-label="Кол-во учебников">
                  {applicationBook.grades.length === 1 &&
                    <div className='d-flex justify-content-between my-column'>
                      <input
                        defaultValue={applicationBook.total}
                        type='number'
                        className={classes.total}
                        id={`total${applicationBook.id}`}
                        // onFocus={() => onFocus(applicationBook.id)}
                        // onBlur={() => onBlur(applicationBook.id)}
                        onChange={(e) => handleTotalChange(applicationBook.id, applicationBook.year, e.target.value)}
                      />
                    </div>
                  }
                  {applicationBook.grades.length > 1 &&
                    <p>{t('school-books.addBook.all_count')} - <b>{applicationBook.total}</b></p>
                  }
                  {applicationBook.grades.length > 1 &&
                      applicationBook.grades.map((grade, index) => (
                          <div key={`total${applicationBook.id}-${grade}`} style={{'marginBottom': 10}}>
                            <label style={{'marginRight': 10}}>{grade} кл. -</label>
                            <input
                              // defaultValue={0}
                              min={0}
                              type='number'
                              className={classes.total}
                              id={`total${applicationBook.id}-${grade}`}
                              // onFocus={() => onFocus(applicationBook.id)}
                              // onBlur={() => onBlur(applicationBook.id)}
                              onChange={(e) => handleTotalChange(applicationBook.id, applicationBook.year, e.target.value, grade)}
                            />
                          </div>
                      ))
                  }
                  {/* {isLibrarian &&
                    <ButtonC
                      variant='change'
                      onClick={() => changeTotal(applicationBook)}
                      id={`button${applicationBook.id}`}
                      className={classes.changeButton}
                    >
                      {t('btn.change')}
                    </ButtonC>
                  } */}
            </td>
            {isLibrarian &&      <td scope="col" data-label="Удалить">
                    <ButtonC variant='delete' onClick={() => setToDeleteBook(applicationBook)}>
                        {/* <FontAwesomeIcon icon={faTrash} className="mx-3"/> */}
                        {t('school-books.addBook.delete_book')}
                    </ButtonC>
                </td>}
              {/* <td>{application.created.split('T')[0]}</td>
              <td>
                {application["study_period_start"]} - {application["study_period_end"]}
              </td>
              <td>{applicationType(application["type"])}</td> */}
              {/* {isJanyKitep && (
                <>
                  <td>{application["district_id"]}</td>
                  <td>{application["district"]}</td>
                </>
              )} */}
              {/* <td>{order['total_quantity']}</td>
                            <td>{order['total_price']}</td> */}
              {/* <td> */}
                {/* {langStatus(application["status"])} */}
                {/* {filterOrders(order['status'])} */}
              {/* </td> */}
              {/* <td>{application["comment"]}</td> */}
              {/* <td>
                <Link to={{ pathname: `/store/general-orders/${application["id"]}` }}>
                  Подробнее
                </Link>
              </td> */}
            </tr>
          ))}
        </tbody>
      </TableComponent>
      <InputField
                  type='text'
                  name='book__name__icontains'
                  placeholder='Комментарий'
                  control={control}
                  onChange={(e) => changeComment(e.target.value)}
                  label = 'Комментарий'
                />
      <button className="btn btn-success text-white" onClick={selectedFileBooksUploadHandler}>{t('school-books.addBook.add_btn')}</button>
    </Layout>
  );
};
