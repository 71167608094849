import i18n from '../i18n'
import {storage} from '../service'

import logoProject from "../logo-okNew.png"
export default logoProject

export const transLang = (str) => {
  if (str === "Русский" || str === "Rus" || str === "Орусча") return "russian";
  if (str === "Кыргызский" || str === "Qirg'iz" || str === "Кыргызча") return "kyrgyz";
  if (str === "Узбекский" || str === "Өзбекче" || str === "O'zbekcha") return "uzbek";
  if (str === "Тажикский" || str === "Тажикче" || str === "Tojik") return "tajik";
};
export const transLangFromEng = (str) => {
  if (str === "kyrgyz") return "Кыргызский";
  if (str === "russian") return "Русский";
  if (str === "uzbek") return "Узбекский";
  if (str === "tajik") return "Тажикский";
  if (str === "all") return "Все языки";
}

export const langLongName = (str) => {
  if (str == 'ru') return "russian"
  if (str == 'ky') return "kyrgyz"
  if (str == 'uz') return "uzbek"
  if (str == 'tj') return "tajik"
}
export const gradeEnum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const replaceString = (str = '') => {
  if (str?.length < 30) return str
  return str?.slice(0, 27)?.concat('... ')
}

export const years = new Array(new Date().getFullYear() - 1989)
for (let i = 0; i < years.length; i++) {
  years[i] = new Date().getFullYear() - i
}
export const langOptions = [
  {value: 'kyrgyz', label: i18n.t('language.kg')},
  {value: 'russian', label: i18n.t('language.ru')},
  {value: 'uzbek', label: i18n.t('language.uz')},
  {value: 'tajik', label: i18n.t('language.tj')},
  {value: '', label: i18n.t('language.all')},
]
export const langStatus = (str) => {
  if (str == 'director_review') return i18n.t('status.director_review')
  if (str == 'director_declined') return i18n.t('status.director_declined')
  if (str == 'district_review') return i18n.t('status.district_review')
  if (str == 'district_approved') return i18n.t('status.district_approved')
  if (str == 'district_declined') return i18n.t('status.district_declined')
  if (str == 'janykitep_review') return i18n.t('status.janykitep_review')
  if (str == 'janykitep_declined') return i18n.t('status.janykitep_declined')
  if (str == 'janykitep_approved') return i18n.t('status.janykitep_approved')
}
export const applicationType = (str) => {
  if (str == 'add') return i18n.t('applicationType.add')
  if (str == 'change') return i18n.t('applicationType.change')
  if (str == 'delete') return i18n.t('applicationType.delete')
}
const role = storage.read('role')
export const labrarian = 'librarian'
export const director = 'director'
export const regObserver = 'reg_observer'
export const distObserver = 'dist_observer'
export const janyKitep = 'jany_kitep'
export const edu_min = "edu_min"
export const coordinator = "coordinator"
export const isLabrarianOrDirector = (role === labrarian || role === director)
export const isLabrarian = (role === labrarian)
export const isDirector = (role === director)
export const isRegObserver = role === regObserver
export const isDistObserver = role === distObserver
export const isJanyKitep = role === janyKitep
export const isLibrarian = role === labrarian
export const isLibrarianOrDistObserver = (role === labrarian || role === distObserver)
export const isEdu_min = role === edu_min
export const isCoordinator = role === coordinator

