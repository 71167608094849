import {Col, Form, Modal, Row} from "react-bootstrap";
import classes from './action-book.module.css';
import {useForm} from "react-hook-form";
import {notifyInfo, notifySuccess} from "../../components/notify";
import {useState, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ButtonC, InputField, SelectField} from "../../components";
import {transLang, years} from "../../lib/helper";
import {thunks} from '../school-books/school-books-reducer';
import {thunks as classBookThunks} from './class-book-reducer';
import {useActions, useAppSelector} from '../../hooks';

const actions = {
  searchSchoolBooks: thunks.searchSchoolBooks,
  addClassBook: classBookThunks.addClassBook
}

export const AddApplicationBook = ({show, setShow, klass, newBooks, setNewBooks, forDelete=false}) => {
  const renderBook = useAppSelector((state) => state.schoolBooks.searchResult)
  const classBooks = useAppSelector((state) => state.classBooks.classBooks)
  const {searchSchoolBooks, addClassBook} = useActions(actions)
  const [query, setQuery] = useState({})
  const {register, control, handleSubmit} = useForm()
  const {t} = useTranslation()
  const [canAdd, setCanAdd] = useState(true)
  const onSubmit = async (data) => {
    setQuery({
      book__name__icontains: data.book__name__icontains,
      year: data.book__year__in,
    })
  }
  const handleClose = () => {
    setShow(false)
  }

  const handleAddClassBook = async (id) => {
    const body = {
      klass: klass?.id,
      school_book: id,
      count: 0,
    }
    const payload = await addClassBook(body)
    if (payload.payload) {
      notifySuccess(`${t('notify.success.added')}`)
    }

  }

  const yearChange = (data) => {
    setQuery((query) => {
      return {...query, year: data}
    })
  }
  const bookChange = (data) => {
    setQuery((query) => {
      return {...query, book__name__icontains: data}
    })
  }
  useMemo(async () => {
    if (klass?.name) {
      const data = {
        ...query,
        book__grades__contains: `{${parseInt(klass.name)}}`,
        book__language__in: transLang(klass.language),
      }
      searchSchoolBooks(data)
    }
  }, [query])
  const createApplicationlBook = (sbData) => {
    // console.log(book);
    // const sbData = {
    //     ...book,
    //     'part': part,
    //     'year': year,
    //     'total': 0
    // }
    if (newBooks.some((book) => book['id'] === sbData['id'] && book['year'] === sbData['year'])) {
        notifyError('Учебник уже есть!')
        // setCanAdd(false)
    } else {
      // console.log(sbData);
      // console.log(newBooks);
      setNewBooks((newBooks) => [...newBooks, sbData])
      // console.log(newBooks);
      notifyInfo(`${t('school-books.addBook.notify_deleteChange')}!`)
      // console.log(sbData);
      // console.log('11111');
      // setCanAdd(false)
    }
}


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="lg"
        scr
      >
        <Modal.Header>
          <Modal.Title>{t('action-books.class-action.add_class_book.btn')}</Modal.Title>
        </Modal.Header>
        {/* Поисковик надо доработать пока делаю основной функционал */}

        {/* <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row xs={1} md={3}>
              <Col>
                <InputField
                  type='text'
                  name='book__name__icontains'
                  placeholder={t('action-books.class-action.add_class_book.name_book')}
                  control={control}
                  onChange={ query}
                />
              </Col>
              <Col>
                <SelectField
                  register={{...register('book__year__in')}}
                  options={[
                    {value: '', label: 'Год'},
                    ...years.map((y) => ({value: y, label: y}))
                  ]}
                  onChange={(event) => yearChange(event.target.value)}
                />
              </Col>
              <Col>
                <ButtonC
                  variant='search'
                  type="submit"
                  style={{marginBottom: '4px'}}
                >{t('btn.search')}
                </ButtonC>
              </Col>
            </Row>

          </Form>
        </Modal.Body> */}
        <div className={classes.line}></div>

        <Modal.Body className={`p-3 ${classes.bookslist}`}>
          <ul >
            {
              renderBook.length > 0 ? renderBook.map((schoolBook) => (
                <li className={`d-flex align-items-center w-100 ${classes.bookItem}`} key={schoolBook.id}>
                  <ul className="ms-4">
                    <li>{t('action-books.class-action.add_class_book.name')}: &nbsp;<b>{schoolBook.book.name}</b> {schoolBook.book.number_of_parts > 1 ? <>({schoolBook.part}-{t('school-books.part')})</> : ''}</li>
                    <li>{t('action-books.class-action.add_class_book.author')}: &nbsp;{schoolBook.book.author}</li>
                    <li>{t('action-books.class-action.add_class_book.Class')}: &nbsp;{schoolBook.book.grades?.join(',')}</li>
                    <li>{t('action-books.class-action.add_class_book.language')}: &nbsp;{schoolBook.book.language}</li>
                    <li>{t('action-books.class-action.add_class_book.year')}: &nbsp;{schoolBook.year}</li>
                    <li>{t('action-books.class-action.add_class_book.count')}: &nbsp;<b>{schoolBook.total}</b></li>
                    <li>{t('action-books.class-action.add_class_book.given')}: &nbsp;<b>{schoolBook.given}</b></li>
                  </ul>
                  {!newBooks.find((el) => el.book.id === schoolBook.book.id && el.year === schoolBook.year && el.part === schoolBook.part) ?
                    <ButtonC
                      variant="add"
                      className='ms-auto'
                      type='submit'
                      onClick={() => createApplicationlBook(schoolBook)}
                      disabled={!canAdd  || (schoolBook.given > 0 && forDelete)}
                    >
                      {t('btn.add')}
                    </ButtonC> :
                    <ButtonC
                      variant="disable"
                      className="ms-auto"
                    >
                      {t('btn.added')}
                    </ButtonC>
                  }
                </li>
              )) :
              <div className='d-flex align-content-center justify-content-center'>
                {t('action-books.class-action.add_class_book.is_empty')}
              </div>
            }
          </ul>

        </Modal.Body>
        <Modal.Footer>
          <ButtonC variant="close" onClick={() => setShow(false)}>
            {t('btn.close')}
          </ButtonC>
        </Modal.Footer>
      </Modal>
    </>
  )
}