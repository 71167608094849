import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "../components/pdf-header/pdf-header.module.css";
import { thunks as historiesThunks } from "../app/store/histories-reducer";
import { thunks as BookExpertsReducer } from "../app/store/bookExpert-reducer";
import { thunks as expertsThunks } from "../app/store/expert-reducer"; // Импорт thunk'ов для экспертов
import "./history-details.css";

export const HistoryDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const historyDocument = useSelector(
    (state) => state.historyDocuments.historyDocuments.results
  );
  const currentHistory = useSelector((state) => state.histories.currentHistory);
  const currentBookExpert = useSelector(
    (state) => state.book_experts.currentBookExpert
  );
  const { results } = useSelector((state) => state.histories.histories);
  const bookExperts = useSelector(
    (state) => state.book_experts.book_experts.results
  );
  const currentExpert = useSelector((state) => state.experts.expert);

  const getHistoryById = (id) => results?.find((h) => h.id == id);
  const getHistoryDocumentById = (id) =>
    historyDocument?.find((d) => d.history == id);

  const historyDocumentDetail = getHistoryDocumentById(id);

  useEffect(() => {
    dispatch(historiesThunks.fetchHistoryById(id))
      .then((action) => {
        if (
          action.payload?.history?.book &&
          typeof action.payload.history.book === "number"
        ) {
          dispatch(
            BookExpertsReducer.fetchBookExpertById(action.payload.history.book)
          );
        }
        // Вызываем fetchExpertById с ID победителя
        if (action.payload?.history?.winner) {
          dispatch(
            expertsThunks.fetchExpertById(action.payload.history.winner)
          );
        }
      })
      .catch((error) => console.error("Error fetching history:", error));

    if (!bookExperts?.length) {
      dispatch(BookExpertsReducer.fetchBookExpertsList());
    }
  }, [dispatch, id, bookExperts, expertsThunks.fetchExpertById]);

  if (!currentHistory) {
    return <div>Loading...</div>;
  }

  const historyData = currentHistory;
  const bookName =
    currentBookExpert?.name ||
    bookExperts?.find((b) => b.id === historyData.book)?.name ||
    "Неизвестный";
  const expertFullName = currentExpert?.fullname || "Неизвестно";

  const handleOpenPdf = () => {
    if (historyDocumentDetail?.pdf_file) {
      window.open(
        historyDocumentDetail.pdf_file,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div className="conainer-bookDetail">
      <div className="card1" onClick={handleOpenPdf}>
        <div className="content">
          <p className="heading">Рандомайзер аркылуу тандалган эксперт</p>
          <p className="para">Аты-жону: {expertFullName}</p>
          <p className="para">
            Тандалган кун:{" "}
            {new Date(historyData.created).toLocaleDateString("ru-RU", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </p>
          <p className="para">Окуу китебинин аталышы: {bookName}</p>
          <p className="para"></p>
          <button className="button" onClick={handleOpenPdf}>
            <span className="lable">Документти коруу</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HistoryDetails;
