import {
  allRead,
  coordinator,
  director,
  distObserver,
  edu_min,
  janyKitep,
  labrarian,
  regObserver,
} from "../lib/helper";
import { hasAccess, hasMatches } from "../route-utils";
import { storage } from "../service";

export const useAuth = ({ pathname }) => {
  const profile = storage.read("access");
  const role = storage.read("role");
  const roles = [
    labrarian,
    director,
    regObserver,
    distObserver,
    janyKitep,
    edu_min,
    coordinator,
  ];
  if (!profile || !roles.includes(role)) {
    storage.clearAll();
    if (pathname === "/sign-in") return;
    // return "/sign-in";
    // Определяем регулярное выражение для пути с ID (например: /history-details/123)
    const historyDetailsPattern = /^\/history-details\/(\d+)$/;

    // Если текущий путь соответствует шаблону /history-details/:id и id в диапазоне от 1 до 1000
    const match = pathname.match(historyDetailsPattern);
    if (match) {
      const id = parseInt(match[1], 10); // Извлекаем ID из URL
      if (id >= 1 && id <= 10000) {
        return; // Если ID в допустимом диапазоне, не перенаправляем
      }
    }
  }
  if (!profile || !roles.includes(role)) {
    storage.clearAll();
    // if (pathname === '/restore-password') return
    // if (pathname === '/open-data') return
    if (pathname === "/sign-in") return;
    return "/sign-in";
  }
  const notFound = !hasMatches(pathname, role);

  if (notFound) return "/not-found";

  const forbidden = !hasAccess(pathname, role);

  if (forbidden) return "/forbidden";
};
