import classes from './book.module.css';
import {ButtonC} from "../buttons/button";
import {useTranslation} from "react-i18next";
import {thunks} from '../../feature/school-books/school-books-reducer';
import {useActions} from "../../hooks";
import {useEffect, useState} from "react";
import {api} from '../../service/old-api-2'
import {notifyError, notifyInfo, notifySuccess} from "../notify";

const actions = {addSchoolBook: thunks.addSchoolBooks}

export const BookCard = ({book, years, newBooks, setNewBooks}) => {
    const {t} = useTranslation()
    const {addSchoolBook} = useActions(actions)
    const [year, setYear] = useState(null)
    const [part, setPart] = useState(1)
    const [canAdd, setCanAdd] = useState(false)
    const parts = new Array(book['number_of_parts'])
    if (book['number_of_parts'] > 1) {
        for (let i = 0; i < parts.length; i++) {
            parts[i] = i + 1
        }
    }
    // x const createSchoolBook = () => {
    //     const sbData = {
    //         ...book,
    //         'part': part,[]ы
    //         'year': year
    //     }
    //     addSchoolBook(sbData).then((response) => {
    //         if (response.payload) {
    //             notifySuccess('Добавлено!')
    //             setCanAdd(false)
    //         }
    //     })
    const createSchoolBook = () => {
        const sbData = {
            ...book,
            'part': part,
            'year': year,
            'total': 0
        }


        if (newBooks.some((book) => book['id'] === sbData['id'] && book['year'] === sbData['year'] && book['part'] === sbData['part'])) {
            notifyError('Учебник уже есть!')
            setCanAdd(false)
        } else {
            setNewBooks((newBooks) => [...newBooks, sbData])
            notifyInfo(`${t('school-books.addBook.notify_add')}`)
            setCanAdd(false)
        }
    }
    const handleYearChange = (event) => {
        if (event.target.value === '') {
            setYear(null);
        } else {
            setYear(event.target.value)
        }
    }
    const handlePartChange = (event) => {
        setPart(event.target.value)
    }
    useEffect(async () => {
        if (year && part) {
            await api.get(`school-books/check/?book_id=${book.id}&year=${year}&part=${part}`)
                .then((response) => {
                    setCanAdd(!response.result)
                })
        }
    }, [year, part])
    return (
        <>
            <div className={classes.bookCard}>
                <div className={classes.bookInfo}>
                    <div>{book.name} {book.number_of_parts > 1 ? <>(1-{book.number_of_parts} {t('school-books.part')})</> : ''}</div>
                    <div>{book.author}</div>
                    <div>{book.language}</div>
                    <div>Класс: {book.grades.join(',')}</div>
                </div>
                <div className={classes.bookAction}>
                    <select onChange={handleYearChange}>
                        <option value="">-----</option>
                        {years.map((year) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                    {parts.length > 1 ? <select onChange={handlePartChange}>
                        <option value="">-----</option>
                        {parts.map((part) => (
                            <option key={part} value={part}>{part}</option>
                        ))}
                    </select> : ''}
                    <ButtonC
                        variant="add"
                        className={`ms-auto ${classes.btnAdd}`}
                        type='submit'
                        onClick={() => createSchoolBook()}
                        disabled={!canAdd}
                    >
                        {t('btn.add')}
                    </ButtonC>
                </div>
            </div>
        </>
    )
}
