import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../service/old-api";
import { notifySuccess, ToastBlock } from "../../components/notify";

const namespace = "history-documents";

// Fetch истории
const fetchHistoryDocuments = createAsyncThunk(`${namespace}/get`, async () => {
  let historyDocuments;

  try {
    historyDocuments = await api.get("history-documents");
  } catch (error) {
    throw new Error(error);
  }

  return { historyDocuments };
});

const fetchHistoryDocumentsById = createAsyncThunk(
  `${namespace}/getById`,
  async (id, { rejectWithValue }) => {
    try {
      const history = await api.get(`history-documents'/${id}`);
      return { history };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Добавление новой истории
const addHistoryDocument = createAsyncThunk(
  `${namespace}/add`,
  async (newHistoryData) => {
    let newHistory;
    try {
      // Отправка POST запроса для добавления новой записи в историю
      newHistory = await api.post("history-documents", {
        body: newHistoryData,
      });
      await dispatch(fetchHistoryDocuments());
    } catch (error) {
      throw new Error(error);
    }

    return { newHistory }; // Вернем добавленную запись
  }
);

const { reducer } = createSlice({
  name: namespace,
  initialState: {
    historyDocuments: [],
    currentHistoryDocuments: null,
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchHistoryDocuments.fulfilled, (state, action) => {
        state.historyDocuments = action.payload.historyDocuments;
      })
      .addCase(fetchHistoryDocumentsById.fulfilled, (state, action) => {
        state.currentHistoryDocuments = action.payload.history;
      })
      .addCase(fetchHistoryDocumentsById.rejected, (state, action) => {
        state.currentHistoryDocuments = null;
        console.error("Ошибка загрузки истории:", action.payload);
      })
      .addCase(addHistoryDocument.fulfilled, (state, action) => {
        // Обновляем список историй, добавляя новую запись
        state.historyDocuments.push(action.payload.historyDocuments);
      }),
});

const thunks = {
  fetchHistoryDocuments,
  addHistoryDocument,
  fetchHistoryDocumentsById,
};

export { reducer, thunks };
