import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../service/old-api";

const namespace = "book-experts";

const fetchBookExpertsList = createAsyncThunk(`${namespace}/get`, async () => {
  let book_experts;
  try {
    book_experts = await api.get("book-experts");
  } catch (error) {
    throw new Error(error);
  }
  return { book_experts };
});

const fetchBookExpertById = createAsyncThunk(
  `${namespace}/getById`,
  async (id, { rejectWithValue }) => {
    try {
      const book_expert = await api.get(`book-experts/${id}`);
      return { book_expert };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const { reducer } = createSlice({
  name: namespace,
  initialState: {
    book_experts: [],
    currentBookExpert: null,
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchBookExpertsList.fulfilled, (state, action) => {
        state.book_experts = action.payload.book_experts;
      })
      .addCase(fetchBookExpertById.fulfilled, (state, action) => {
        state.currentBookExpert = action.payload.book_expert;
      })
      .addCase(fetchBookExpertById.rejected, (state, action) => {
        state.currentBookExpert = null;
        console.error("Ошибка загрузки книги:", action.payload);
      }),
});

const thunks = { fetchBookExpertsList, fetchBookExpertById };
export { reducer, thunks };
