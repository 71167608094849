import {
  coordinator,
  director,
  distObserver,
  edu_min,
  isLabrarianOrDirector,
  janyKitep,
  labrarian,
  regObserver,
} from "./lib/helper";
import * as pages from "./pages";

const allRoles = [
  labrarian,
  director,
  regObserver,
  distObserver,
  janyKitep,
  edu_min,
  coordinator,
];

export const routes = new Map()
  .set("/", {
    page: isLabrarianOrDirector ? pages.SchoolEvents : pages.News,
    roles: [
      labrarian,
      director,
      regObserver,
      distObserver,
      janyKitep,
      edu_min,
      coordinator,
    ],
  })
  .set("/news", {
    page: pages.News,
    roles: [
      labrarian,
      director,
      regObserver,
      distObserver,
      janyKitep,
      edu_min,
      coordinator,
    ],
  })
  .set("/notifications", {
    page: pages.Notifications,
    roles: [
      labrarian,
      director,
      regObserver,
      distObserver,
      janyKitep,
      edu_min,
      coordinator,
    ],
  })
  .set("/action-books/:id", {
    page: pages.ClassAction,
    roles: [labrarian, director],
  })
  .set("/teacher-books/:id", {
    page: pages.TeacherBooks,
    roles: [labrarian, director],
  })
  .set("/a1-activity-schools/:id", {
    page: pages.A1ActiveSchoolDetails,
    roles: [janyKitep, edu_min],
  })
  .set("/b1-filling-report", {
    page: pages.B1FillingReport,
    roles: [janyKitep, edu_min],
  })
  .set("/b1-filling-report-dist", {
    page: pages.B1FillingReportDist,
    roles: [janyKitep, edu_min],
  })
  .set("/classes", {
    page: pages.Classes,
    roles: [labrarian, director],
  })
  .set("/school-events", {
    page: pages.SchoolEvents,
    roles: [labrarian, director],
  })
  .set("/school-news", {
    page: pages.SchoolNews,
    roles: [labrarian, director],
  })
  .set("/report", {
    page: pages.Report,
    roles: allRoles,
  })
  .set("/report-result/:id", {
    page: pages.D10SubDistResult,
    roles: allRoles,
  })
  .set("/report-region-details/:id", {
    page: pages.D10SubRegDetails,
    roles: allRoles,
  })
  .set("/report-region-schools/:id", {
    page: pages.D10SubRegDistSchools,
    roles: allRoles,
  })
  .set("/report-republic-dists/:id", {
    page: pages.D10SubRepubDists,
    roles: allRoles,
  })
  .set("/dev", {
    page: pages.developmentMode,
    roles: allRoles,
  })
  .set("/action-books", {
    page: pages.ActionBooks,
    roles: [labrarian, director],
  })
  .set("/school-books", {
    page: pages.BooksFond,
    roles: [labrarian, director],
  })
  .set("/school-books/:id", {
    page: pages.SchoolGradesBooks,
    roles: allRoles,
  })
  .set("/school-books/rent/:id", {
    page: pages.WhomItIsRent,
    roles: allRoles,
  })
  .set("/school-books-application", {
    page: pages.BooksFondApplication,
    roles: [labrarian, director],
  })
  .set("/school-books-application-new", {
    page: pages.BooksFondApplicationNew,
    roles: [labrarian, director],
  })
  .set("/school-books-application-change", {
    page: pages.BooksFondApplicationChange,
    roles: [labrarian, director],
  })
  .set("/school-books-application-delete", {
    page: pages.BooksFondApplicationDelete,
    roles: [labrarian, director],
  })
  .set("/profile", {
    page: pages.ProfileSettings,
    roles: allRoles,
  })
  .set("/profile-settings", {
    page: pages.ProfileSettings,
    roles: [labrarian, director],
  })
  .set("/payments", {
    page: pages.Payments,
    roles: allRoles,
  })
  .set("/videos", {
    page: pages.VideoLessons,
    roles: allRoles,
  })
  .set("/restore-password", {
    page: pages.RestorePasswordPage,
    roles: allRoles,
  })
  .set("/open-data", {
    page: pages.OpenData,
    // roles: ''
  })
  .set("/sign-in", {
    page: pages.Login,
    roles: allRoles,
  })
  .set("/not-found", {
    page: pages.Home,
    roles: allRoles,
  })
  .set("/forbidden", {
    page: pages.Home,
    roles: allRoles,
  })
  .set("/contacts", {
    page: pages.Contacts,
    roles: [...allRoles],
  })
  .set("/news-list/:id", {
    page: pages.NewsDetails,
    roles: [...allRoles],
  })
  .set("/questions", {
    page: pages.Questions,
    roles: [...allRoles],
  })
  .set("/store", {
    page: pages.BookShowcase,
    roles: [labrarian],
  })
  .set("/store/cart", {
    page: pages.Cart,
    roles: [labrarian, director],
  })
  .set("/store/checkout", {
    page: pages.Checkout,
    roles: [labrarian, director],
  })
  .set("/store/checkout-general-order", {
    page: pages.CheckoutGeneralOrder,
    roles: [distObserver, janyKitep, edu_min],
  })
  .set("/store/orders", {
    page: pages.OrderList,
    roles: [labrarian, director, distObserver],
  })
  .set("/store/general-orders", {
    page: pages.GeneralOrderList,
    roles: [distObserver],
  })
  .set("/store/general-orders-districts", {
    page: pages.OrderDistrictList,
    roles: [janyKitep, edu_min],
  })
  .set("/store/general-orders-districts/:id", {
    page: pages.OrderDistrictSchoolList,
    roles: [janyKitep, edu_min],
  })
  .set("/store/not-act-schools/:type", {
    page: pages.NotActSchoolList,
    roles: [janyKitep, edu_min],
  })
  .set("/store/orders-shools", {
    page: pages.OrderSchoolList,
    roles: [distObserver, janyKitep, edu_min],
  })
  .set("/store/orders-shools/:id", {
    page: pages.OrderSchoolDetailList,
    roles: [distObserver, janyKitep, edu_min],
  })
  .set("/store/orders-district/:id", {
    page: pages.DistrictsOrderList,
    roles: [janyKitep, edu_min],
  })
  .set("/store/orders/:id", {
    page: pages.OrderDetail,
    roles: [labrarian, director, distObserver, janyKitep, edu_min],
  })
  .set("/store/general-orders/:id", {
    page: pages.GeneralOrderDetail,
    roles: [distObserver, janyKitep, edu_min],
  })
  .set("/open-data-dist/:id", {
    page: pages.OpenDataDistricts,
    roles: labrarian,
  })
  .set("/expert-home", {
    page: pages.RandomExpert,
    roles: [coordinator],
  })
  .set("/history-list", {
    page: pages.Historylist,
    roles: [coordinator],
  })
  .set("/history-details/:id", {
    page: pages.HistoryDetails,
  })
  .set("/detail-balance", {
    page: pages.DetailBalance,
    roles: [labrarian],
  });

export const routeArray = [...routes].map(([path, r]) => ({ ...r, path }));
