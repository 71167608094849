import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../service/old-api";

const namespace = "experts";

const fetchExpertsList = createAsyncThunk(`${namespace}/get`, async () => {
  let experts;

  try {
    experts = await api.get("experts");
  } catch (error) {
    throw new Error(error);
  }

  return { experts };
});
const fetchExpertById = createAsyncThunk(
  `${namespace}/getById`,
  async (id, { rejectWithValue }) => {
    try {
      const expert = await api.get(`experts/${id}`);
      return { expert };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const { reducer } = createSlice({
  name: namespace,
  initialState: {
    experts: [],
    expert: null,
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchExpertsList.fulfilled, (state, action) => {
        state.experts = action.payload.experts;
      })
      .addCase(fetchExpertById.fulfilled, (state, action) => {
        state.expert = action.payload.expert;
      })
      .addCase(fetchExpertById.rejected, (state, action) => {
        state.expert = null;
        console.error("Ошибка загрузки книги:", action.payload);
      }),
});

const thunks = { fetchExpertsList, fetchExpertById };

export { reducer, thunks };
