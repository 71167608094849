import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { ButtonC, PdfHeader, TableComponent } from "../../components";
import { useAppSelector } from "../../hooks";

export const StudentAmountList = () => {
  const classes = useAppSelector((state) => state.classes.classes);
  const { t } = useTranslation();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const totalStudents = classes.reduce(
    (sum, klass) => sum + klass.student_count,
    0
  );

  const list = classes.map((klass, index) => (
    <tr key={klass.id}>
      <th data-label="№">{index + 1}</th>
      <td data-label="Классы">{klass.name.toUpperCase()}</td>
      <td data-label="Кл. руководитель">{klass.teacher?.fullname}</td>
      <td data-label="ПИН">{klass.teacher?.pin}</td>
      <td data-label="Телефон">{klass.teacher?.phone}</td>
      <td data-label="E-mail">{klass.teacher?.email}</td>
      <td data-label="Количество учащихся	">{klass.student_count}</td>
    </tr>
  ));

  return (
    <>
      <div>
        <TableComponent ref={componentRef}>
          <ButtonC
            variant="download"
            onClick={handlePrint}
            style={{
              float: "left",
              marginBottom: "15px",
              backgroundColor: "red",
              color: "#fff",
            }}
          >
            PDF <FontAwesomeIcon style={{ color: "#fff" }} icon={faFilePdf} />
          </ButtonC>
          <PdfHeader title={t("report.show-class.title")} />
          <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">{t("report.show-class.Class")}</th>
              <th scope="col">{t("report.show-class.teacher")}</th>
              <th scope="col">{t("report.show-class.pin")}</th>
              <th scope="col">{t("report.show-class.phone")}</th>
              <th scope="col">E-mail</th>
              <th scope="col">{t("report.show-class.student_count")}</th>
            </tr>
          </thead>
          <tbody>
            {list}
            <tr>
              <td colSpan="6">{t("report.show-class.total")}</td>
              <td>{totalStudents}</td>
            </tr>
          </tbody>
        </TableComponent>
      </div>
    </>
  );
};
