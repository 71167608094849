import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../service/old-api";
import { notifySuccess, ToastBlock } from "../../components/notify";

const namespace = "histories";

const fetchHistories = createAsyncThunk(`${namespace}/get`, async () => {
  let histories;

  try {
    histories = await api.get("histories");
  } catch (error) {
    throw new Error(error);
  }

  return { histories };
});
const fetchHistoryById = createAsyncThunk(
  `${namespace}/getById`,
  async (id, { rejectWithValue }) => {
    try {
      const history = await api.get(`histories/${id}`);
      return { history };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const addHistory = createAsyncThunk(
  `${namespace}/add`,
  async (newHistoryData) => {
    let newHistory;
    try {
      newHistory = await api.post("histories", { body: newHistoryData });
      await dispatch(fetchHistories());
    } catch (error) {
      throw new Error(error);
    }

    return { newHistory };
  }
);

const { reducer } = createSlice({
  name: namespace,
  initialState: {
    histories: [],
    currentHistory: null,
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchHistories.fulfilled, (state, action) => {
        state.histories = action.payload.histories;
      })
      .addCase(fetchHistoryById.fulfilled, (state, action) => {
        state.currentHistory = action.payload.history;
      })
      .addCase(fetchHistoryById.rejected, (state, action) => {
        state.currentHistory = null;
        console.error("Ошибка загрузки истории:", action.payload);
      })
      .addCase(addHistory.fulfilled, (state, action) => {
        state.histories.push(action.payload.newHistory);
      }),
});

const thunks = { fetchHistories, addHistory, fetchHistoryById };
export { reducer, thunks };
