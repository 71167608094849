import './App.css';
import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {notifyError, ToastBlock} from '../components/notify';
import {routeArray} from '../routes';
import {useAuth} from '../hooks/use-auth';
import {useTranslation} from 'react-i18next';
import {useActions, useAppSelector} from '../hooks';
import {selectors, thunks} from './store/model';
// import SnowFall from '../components/snowfall/SnowFall';

const routes = routeArray.map((r) => (
  <Route key={r.path} path={r.path} component={r.page} exact />
))

function App() {
  const {t} = useTranslation()
  const appRestored = useAppSelector((s) => selectors.hasRestored(s.app))
  const location = useLocation()
  const redirect = useAuth(location)
  const {restoreSession} = useActions(thunks)
  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    if (!appRestored) restoreSession()
  }, [restoreSession, appRestored])


  useEffect(() => {
    if (location.pathname === '/not-found') notifyError(t('error_message.not-found'))
    if (location.pathname === '/forbidden') notifyError(t('error_message.forbidden'))
  }, [location.pathname])

  if (redirect !== undefined) return <Redirect to={redirect} />

  return (
    <div>
      {/* <SnowFall/> */}
      <ToastBlock />
      <Switch>
        {routes}
      </Switch>
    </div>
  );
}

export default App;
